// Import Variable
@import "../../assets/scss/var.scss";

.entry {
  p,
  ul,
  ol {
    color: $white-gray;
    font: $body;
    margin-bottom: 0;
    line-height: 25px;
    word-break: break-word;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Gilroy";
    font-weight: 400;
    color: $dark-gray;
    margin-bottom: 24px;
  }

  blockquote {
    color: $gray;
    font-size: 28px;
    font-family: "Gilroy";
    font-weight: 300;
    position: relative;
    margin-bottom: 24px;
    padding-left: 20px;
    border-left: 5px solid $main;
  }

  pre {
    color: #dedede;
    padding: 20px;
    background: #454545;
    border-radius: 7px;
  }

  img {
    margin-bottom: 24px;
    max-width: 100%;

    &.full {
      width: 100%;
    }

    &.half {
      width: 48%;
    }
  }

  .the-end {
    width: 100%;
    border-bottom: 1px solid #8d99ae59;
    margin-top: 8px;

    span {
      display: table;
      margin: 0 auto -16px;
      background-color: #fff;
      padding: 3px 10px;
      font-family: "Gilroy";
      color: #8d99ae59;
      font-weight: 200;
      border: 1px solid #8d99ae59;
      border-radius: 5px;
    }
  }
}

.comments {
  margin-top: 32px;

  .title {
    font: $title-big;
    color: $gray;
  }

  ol {
    list-style: none;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      .comment {
        margin-top: 24px;
        padding: 24px;
        background-color: #edf2f4;
        border-radius: 10px;
        display: flex;

        img {
          width: 62px;
          height: 62px;
          object-fit: cover;
          object-position: center;
          border-radius: 7px;
          margin-right: 14px;
        }

        .text {
          .name {
            color: $dark-gray;
            font-weight: 400;
            font-family: "Gilroy";
            font-size: 20px;
            margin-top: 2px;
          }

          .body {
            font: $body;
            color: $gray;
            margin-top: 10px;
          }

          button {
            margin-top: 16px;
            background: unset;
            border: unset;
            color: $main;
            font-size: 14px;
            font-weight: 300;
            font-family: "Gilroy";
            outline: unset;

            svg {
              margin-right: 5px;
            }
          }
        }

        @media (max-width: 768px) {
          .text {
            .name {
              font-size: 18px;
            }

            .body {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }

  form {
    textarea,
    input {
      width: 100%;
      height: 53px;
      padding: 16px;
      border-radius: 10px;
      background-color: #edf2f4;
      border: none;
      margin-top: 24px;
      font: $body;

      &:focus {
        outline: $main solid 2px;
      }

      &::placeholder {
        color: $gray;
      }
    }

    textarea {
      resize: none;
    }

    .send {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(calc(-50% + 9px));
      width: 35px;
      height: 35px;
      border: none;
      background: $main;
      border-radius: 7px;

      svg {
        margin-top: -5px;
      }

      @media (max-width: 768px) {
        transform: translateY(calc(-50% + 11px));
      }
    }
  }
}
