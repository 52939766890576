// Import Variable
@import "../../assets/scss/var.scss";

.cards {
  .blog-card {
    background-color: #edf2f4;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 32px;

    .date {
      position: absolute;
      left: 24px;
      top: 24px;
      text-align: center;
      background-color: $main;
      border-radius: 7px;
      padding: 12px;
      font-size: 16px;
      font-weight: 200;
      color: #fff;
      font-family: "Gilroy";
      transition: all 0.5s;
      opacity: 1;
      z-index: 9;

      span {
        display: block;
        font-size: 20px;
        font-weight: 300;
        margin-bottom: -2px;
      }
    }

    .thumb {
      position: relative;
      overflow: hidden;

      .overlay {
        background-color: rgba(0, 0, 0, 0.37);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        transition: all 0.5s;
        z-index: 9;
        opacity: 0;
      }

      img {
        width: 100%;
        transition: all 0.5s;
        height: 240px;
        object-fit: cover;
      }
    }

    .text {
      padding: 20px;

      h2 {
        font: $title-small;
        color: $dark-gray;
        margin-bottom: 13px;
      }

      p {
        font: $body;
        color: $white-gray;
        line-height: 24px;
        margin-bottom: 0;
        word-break: break-word;
      }
    }

    &:hover {
      .date {
        opacity: 0;
      }

      .thumb {
        .overlay {
          opacity: 1;
        }

        img {
          transform: scale(1.1);
        }
      }
    }
  }
}
