// Colors
$main: var(--bg-color);
$white: #fff;
$gray: #646F87;
$dark-gray: #293651;
$white-gray: #8D99AE;
$light-gray: #D3DCEE;

// Fonts
$body: 200 16px 'Gilroy', 'Al-Jazeera';
$title: 400 24px 'Gilroy', 'Al-Jazeera';
$title-small: 300 20px 'Gilroy', 'Al-Jazeera';
$title-med: 300 24px 'Gilroy', 'Al-Jazeera';
$title-big: 400 24px 'Gilroy', 'Al-Jazeera';
$big: 400 48px 'Gilroy', 'Al-Jazeera';