// Import Variables
@import '../../assets/scss/var.scss';

.About {
  section {
    margin-bottom: 40px;

    h3 {
      font-size: 24px;
      color: $dark-gray;
      font: $title;
      margin-bottom: 24px;
      display: -webkit-inline-box;
    }
  }

  .about-me {
    p {
      color: $white-gray;
      font: $body;
      line-height: 25px;
      white-space: break-spaces;
    }
  }

  .services {
    .single-service {
      text-align: center;
      box-shadow: 0 0 15px #0000001f;
      padding: 30px 20px;
      border-radius: 10px;
      width: 100%;
      .icon {
        width: 92px;
        height: 92px;
        background: $main;
        border-radius: 50%;
        margin: 0 auto 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.9);
        }

        img {
          max-width: 47px;
          max-height: 47px;
          position: relative;
          z-index: 99;
        }
      }

      h1 {
        font-family: 'Gilroy';
        font-size: 20px;
        color: $dark-gray;
        font-weight: 300;
      }

      p {
        color: $white-gray;
        font-family: 'Gilroy';
        font-weight: 200;
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 13px;
      }

      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  .work-experience {
    .works {
      position: relative;

      .exp {
        margin-left: 30px;
        position: relative;
        margin-bottom: 48px;

        h1 {
          font-size: 20px;
          color: $gray;
          font-family: 'Gilroy';
          font-weight: 300;
          margin-bottom: 12px;

          span {
            color: $main;
          }
        }

        .meta {
          display: inline-flex;
          align-items: center;
          margin-bottom: 14px;

          .info {
            span {
              color: $dark-gray;
              font-size: 14px;
              font-family: 'Gilroy';
              font-weight: 200;
            }

            .icon {
              fill: $main;
            }

            &.location {
              margin-right: 10px;
            }

            &.position {
              margin-right: 10px;
            }
          }

          @media (max-width: 768px) {
            display: block;

            .info {
              margin-bottom: 10px;
              margin-right: 0;
            }
          }
        }

        p {
          color: $gray;
          font-size: 14px;
          font-family: 'Gilroy';
          white-space: pre-wrap;
          font-weight: 200;
        }

        &::before {
          content: '';
          width: 19px;
          height: 19px;
          display: block;
          left: -37px;
          position: absolute;
          border-radius: 50%;
          background-color: $light-gray;
        }
      }

      .line {
        background-color: #d3dcee;
        width: 5px;
        height: 98%;
        position: absolute;
        top: 6px;

        &::after {
          content: '';
          width: 11px;
          height: 11px;
          background-color: $main;
          border-radius: 50%;
          bottom: -1px;
          display: block;
          position: absolute;
          left: -3px;
        }
      }
    }
  }

  .testimonials {
    position: relative;

    .single-testi {
      overflow: hidden;
      padding: 19px;
      background-color: #edf2f4;
      border-radius: 10px;
      display: flex;
      position: relative;

      .img {
        margin-right: 19px;
        width: 103px;
        height: 103px;
        background-position: center;
        background-size: cover;
        border-radius: 7px;
      }

      .text {
        width: 87%;

        .title {
          font: $title;
          font-weight: 300 !important;
          color: $main;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        p {
          color: $gray;
          font-size: 16px;
          font-weight: 200;
          font-family: 'Gilroy';
          margin-bottom: 0;
          width: calc(100% - 20px);
        }
      }

      svg {
        position: absolute;
        right: -14px;
        bottom: -18px;
      }

      .quote {
        fill: #8d99ae42;
      }
    }

    .arrow {
      fill: $white-gray;
    }

    .slick-arrow {
      position: absolute;
      top: -50px;
      width: 27px;
      height: 27px;
      border-radius: 5px;
      border: 1.5px solid $white-gray;
      display: flex !important;
      align-items: center;
      outline: unset;
      justify-content: center;
      background-color: unset;

      &.slick-next {
        right: 0;
      }

      &.slick-prev {
        right: 35px;
        transform: rotate(180deg);
      }
    }

    @media (max-width: 768px) {
      .single-testi {
        .text {
          .title {
            margin-top: 12px;
            font-size: 16px;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }
  }

  .skills {
    .wrapper {
      margin-top: 20px;

      .single-skill {
        margin-bottom: 30px;

        h4 {
          color: $gray;
          font-size: 18px;
          font-weight: 300;
          font-family: 'Gilroy';
          margin-bottom: 0;

          span {
            float: right;
            color: $main;
          }
        }

        .bar {
          margin-top: 8px;
          width: 100%;
          height: 10px;
          border-radius: 4px;
          background-color: #646f8733;

          .level {
            transition: all 2.4s;
            width: 0;
            height: 10px;
            border-radius: 4px;
            background-color: $main;
          }
        }
      }
    }
  }
}
