@import "../../assets/scss/var.scss";

// Loader
.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy";
  font-weight: 200;
  color: $gray;
  z-index: 9999;
  top: 0;

  .multi-spinner-container {
    width: 150px;
    height: 150px;
    position: relative;
    margin: 30px auto;
    overflow: hidden;

    .multi-spinner {
      position: absolute;
      width: calc(100% - 9.9px);
      height: calc(100% - 9.9px);
      border: 5px solid transparent;
      border-top-color: var(--bg-color);
      border-radius: 50%;
      -webkit-animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
      animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
    }
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}