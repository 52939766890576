// Import Variable
@import "../../assets/scss/var.scss";

.Portfolio {
  .categories {
    a {
      color: $white-gray;
      font: $body;
      transition: all 0.5s;

      &:hover {
        color: $main;
      }

      &.active {
        color: $main;
        font-weight: 300;
      }

      &::after {
        content: "";
        width: 5px;
        height: 5px;
        background-color: $white-gray;
        border-radius: 50%;
        display: inline-block;
        margin: 0 10px;
        position: relative;
        top: -4px;
      }

      &:last-of-type {
        &::after {
          content: unset;
        }
      }
    }

    @media (max-width: 768px) {
      overflow: auto;
      display: flex;

      a {
        margin-right: 20px;
        white-space: pre;

        &::after {
          content: unset;
        }
      }
    }
  }

  .works {
    .work {
      margin-bottom: 24px;

      .thumbnail {
        margin-bottom: 16px;
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        img {
          width: 100%;
          height: 204px;
          object-fit: cover;
          object-position: center;
          transition: all 0.5s;
        }

        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.37);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: all 0.5s;
          z-index: 99;
        }
      }

      h3 {
        font: $title-med;
        color: $gray;
        margin-bottom: 8px;
      }

      span {
        font: $body;
        color: $main;
        display: block;
      }

      &:hover {
        .thumbnail {
          .overlay {
            opacity: 1;
          }
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
