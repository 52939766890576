// Import Variable
@import "../../assets/scss/var.scss";

.entry {
  .map {
    margin-top: 8px;

    iframe {
      width: 100%;
      border-radius: 15px;
      height: 267px;
    }
  }

  form {
    textarea,
    input {
      width: 100%;
      height: 53px;
      padding: 16px;
      border-radius: 10px;
      background-color: #edf2f4;
      border: none;
      margin-top: 24px;
      font: $body;

      &:focus {
        outline: $main solid 2px;
      }

      &::placeholder {
        color: $gray;
      }
    }

    textarea {
      resize: none;
      height: auto;
    }

    .send {
      border: none;
      background: $main;
      border-radius: 10px;
      width: 100%;
      margin-top: 16px;
      color: #fff;
      font-family: 'Gilroy';
      font-weight: 400;
      font-size: 16px;
      height: 53px;
    }
  }
}