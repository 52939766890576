@import '../../assets/scss/var.scss';

.left-bar {
  width: 100%;
  height: 100vh;
  background-color: $main;
  display: flow-root;
  position: fixed;

  .top-bar-wrapper {
    .logo {
      margin: 20px auto 0;
      display: block;
      max-width: 175px;
      max-height: 75px;
    }

    .mobile-menu {
      display: none;
    }
  }

  .squares {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    .a {
      fill: $white;
    }
  }

  .meta {
    position: absolute;
    bottom: 30px;
    left: 30px;

    .info {
      color: $white;
      font-weight: 100;
      margin-bottom: 17px;

      span {
        display: block;
        font-family: 'Gilroy';
        font-weight: 300;
      }
    }

    .social {
      margin-top: 23px;

      a {
        margin-right: 15px;

        .b {
          fill: $white;
        }
      }
    }
  }

  @media (max-width: 768px) {
    position: absolute;
    height: 90px;
    top: 0;
    z-index: 99;
    overflow: hidden;
    transition: all 0.3s;

    &.menuOpened {
      height: 330px;
    }

    .top-bar-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        margin: 20px 0 0;
      }

      .mobile-menu {
        margin-top: 17px;
        display: inline-block;
        background: none;
        color: #fff;
        border: none;
        font-size: 30px;
        outline: none;
      }
    }

    .squares {
      display: none;
    }

    .meta {
      margin: 30px;
      position: unset;
    }
  }
}
