// Import Variables
@import 'assets/scss/var';

// Import Libraries
@import './assets/scss/bootstrap/bootstrap.scss';
@import './assets/scss/font-awesome/fontawesome';
@import './assets/scss/font-awesome/solid';
@import './assets/scss/font-awesome/regular';
@import './assets/scss/font-awesome/brands';
@import './assets/scss/quill.bubble.scss';

// Fonts
@font-face {
  font-family: 'Gilroy';
  src: url(./assets/fonts/Gilroy-Bold.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url(./assets/fonts/Gilroy-SemiBold.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url(./assets/fonts/Gilroy-Medium.ttf);
  font-weight: 200;
}

@font-face {
  font-family: 'Gilroy';
  src: url(./assets/fonts/Gilroy-Light.ttf);
  font-weight: 100;
}

// Arabic Font
@font-face {
  font-family: 'Al-Jazeera';
  src: url(./assets/fonts/Al-Jazeera-Arabic-Regular.ttf);
}

:root {
  // Variables
  --bg-color: #9f9f9f;
}

body {
  margin: 0;
  font-family: 'Gilroy', 'Al-Jazeera', serif;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow-y: hidden;
  overflow-x: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none !important;
}

.Main {
  overflow: hidden;
  position: relative;

  .squ {
    position: fixed;
    right: -35px;
    top: -35px;

    .squares {
      fill: #dfdfdf;
    }
  }

  @media (max-width: 768px) {
    padding-top: 110px;
    padding-bottom: 40px;

    .squ {
      display: none;
    }
  }
}
// Constants
.all-content {
  width: calc(100% - 88px);
  display: flow-root;
  padding: 50px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 50px 25px;
  }
}

.main-color {
  fill: $main;
}

section {
  margin-bottom: 40px;

  h3 {
    font-size: 24px;
    color: $dark-gray;
    font: $title;
    margin-bottom: 24px;
    display: -webkit-inline-box;
  }
}

iframe {
  display: block;
  // visibility: visible;
}

// React Toastify
.Toastify__toast-body {
  font-family: 'Gilroy';
  font-weight: 200;
}
