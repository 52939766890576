@import '../../assets/scss/var.scss';

.Home {
    .all-content {
        margin-left: -120px;
        padding: 0;

        .row {
            height: 100vh;
        }

        .pp {
            background-color: $gray;
            box-shadow: 0 0 15px #0000001C;
            // width: 375px;
            height: 480px;
            border-radius: 15px;
            background-position: center;
            background-size: cover;
            margin: 0 auto;
            max-width: 330px;

            @media screen and (min-width: 1400px) {
                height: 580px;
                max-width: 400px;
            }
        }

        @media screen and (min-width: 1400px) {
            margin-left: -175px;
        }

        .details {
            h3 {
                font-size: 50px;
                font-family: 'Gilroy';
                font-weight: 200;
                color: $main;
                margin-bottom: 0;
            }

            h1 {
                color: $dark-gray;
                font: $big;
                margin-bottom: 8px;
            }

            span {
                font-size: 17px;
                color: $dark-gray;
                font-family: 'Gilroy';
                font-weight: 300;
            } 

            p {
                font: $body;
                margin-bottom: 0;
                margin-top: 16px;
                color: $white-gray;
                line-height: 25px;
            }

            .links {
                margin-top: 24px;
                display: inline-flex;

                a {
                    padding: 14px 26px;
                    border-radius: 7px;
                    color: $white;
                    position: relative;
                    top: 0;
                    font: $body;
                    box-shadow: 0 0 0 #0000001C;
                    transition: all 0.3s;

                    &.download {
                        margin-right: 10px;
                        background-color: $main;
                    }

                    &.contact {
                        background-color: $gray;
                    }

                    svg {
                        margin-left: 5px;
                    }

                    .arrow {
                        fill: $white;
                    }

                    &:hover {
                        top: -4px;
                        box-shadow: 0 5px 2px #00000025;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            margin-left: 0;
            padding: 50px 25px;

            .row {
                height: auto;
            }

            .pp {
                margin-bottom: 30px;
                height: 366px;
            }

            .details {
                h3 {
                    font-size: 42px;
                }

                h1 {
                    font-size: 40px;
                }

                .links {
                    display: block;

                    a {
                        display: block;
                        width: 100%;
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}