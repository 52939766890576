// Import Variable
@import "../../assets/scss/var.scss";

.SinglePortfolio {
  padding: 48px 0;
  position: relative;

  .contentWrapper {
    img {
      max-width: 100%;
    }
  }

  section {
    .gallery {
      margin-top: 30px;

      .gallery-image {
        object-fit: cover;
        object-position: center;
        max-width: 100%;
        cursor: zoom-in;
        margin-bottom: 30px;
        border-radius: 10px;

        &.full {
          width: 100%;
        }

        &.half {
          width: 48%;

          @media (max-width: 768px) {
            height: 150px;
          }
        }
      }
    }

    .videos {
      display: inline-block;
      width: 100%;

      iframe {
        width: 100%;
        height: 400px;
        border-radius: 10px;
        margin-bottom: 30px;
        visibility: visible;

        &.full {
          width: 100%;
        }

        &.half {
          width: 48%;

          @media (max-width: 768px) {
            height: 150px;
          }
        }
      }
    }
  }

  .sidebar {
    background-color: #edf2f4;
    padding: 26px 31px;
    border-radius: 10px;

    h3 {
      font: $body;
      font-weight: 400;
      color: $gray;
      margin-bottom: 14px;
    }

    .tags {
      margin-bottom: 24px;
      span {
        font-size: 12px !important;
        border-radius: 4px;
        border: 1.5px solid;
        color: $white-gray;
        font: $body;
        padding: 3px 12px;
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }

  .links {
    .link {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      a {
        color: $white-gray;
        font: $body;
        font-size: 14px;
        text-decoration: underline !important;
        word-break: break-word;
        flex-basis: 97%;
      }

      svg {
        margin-right: 8px;
      }
    }
  }

  .exit {
    position: absolute;
    right: 120px;
    top: 50px;
    background-color: #edf2f4;
    color: $white-gray;
    width: 44px;
    height: 44px;
    border-radius: 7px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 768px) {
      z-index: 999;
      top: 20px;
      right: 20px;
    }
  }

  h3 {
    color: $dark-gray;
  }

  p,
  ul,
  ol {
    font: $body;
    color: $white-gray;
    line-height: 25px;
  }

  blockquote {
    color: $gray;
    padding-left: 30px !important;
    border-left: 5px solid $main;
    border-left: 5px solid #1FA78F;
    background: #edf2f4;
    padding: 20px;
    font-style: italic;
    border-radius: 10px;
  }

  .popup {
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    z-index: 99999;
    left: 0;
    top: 0;
    display: none;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);

    p {
      color: #fff;
      text-align: center;
      margin-top: 15px;
    }

    img {
      max-width: 100%;
      max-height: 97vh;
    }

    .close-preview {
      position: absolute;
      right: 40px;
      top: 40px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    margin-top: -110px;
  }
}
