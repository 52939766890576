@import '../../assets/scss/var.scss';

.navi {
    box-shadow: 0px 0px 15px #0000001F;
    background-color: #fff;
    padding: 18px 15px;
    display: inline-block;
    position: fixed;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 9px;

    a {
        display: grid;
        margin-bottom: 20px;
        transition: all 0.3s;
        
        .off {
            fill: $gray;
        }

        &.active {
            .off {
                fill: $main !important;
            }
        }

        &:hover {
            .off {
                fill: $main !important;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    @media (max-width: 768px) {
        bottom: 30px;
        transform: translateX(-50%);
        left: 50%;
        right: unset;
        top: unset;
        display: flex;

        a {
            display: unset;
            margin: 0 20px;
        }
    }
}